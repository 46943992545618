import React from 'react';

interface BlobProps {
  path: string,
  data: number[][],
  bounds: {
    width: any,
    height: any
  },
  color: string
}

const BlobMaker: React.FC<BlobProps> = ({ path, data, bounds, color, ...props }: BlobProps) => {
  return (
    <svg viewBox={`0 0 250 100`} xmlns="http://www.w3.org/2000/svg">
        <path
        vectorEffect="non-scaling-stroke"
        fill={color} d={path} transform={`translate(100 10)`} />
    </svg>
  )
}

export default BlobMaker