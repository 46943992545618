const data = [
  [
    24,
    83
  ],
  [
    47,
    81
  ],
  [
    79,
    36
  ],
  [
    19,
    8
  ],
  [
    10,
    11
  ],
  [
    -20,
    24
  ],
  [
    11,
    73
  ]
];

export default data;