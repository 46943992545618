import React from 'react'

const Details: React.FC = () => {
    return (
        <section className="details">
            <h1>About</h1>
            <p>Generate random SVG blobs with Bloby.</p>
            <a href="https://www.producthunt.com/posts/bloby?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-bloby" target="_blank" rel="noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=297026&theme=dark" alt="Bloby - Generate random SVG shapes | Product Hunt" style={{width: '250px', height: '54px'}} width="250" height="54" /></a>
        </section>
    )
}

export default Details
