import React, { useEffect, useRef, useState } from 'react';
import { line, curveCatmullRomClosed } from 'd3-shape';
import BlobMaker from './components/BlobMaker';
import { TwitterPicker } from 'react-color';

import './App.css';
import CustomModal from './components/CustomModal';
import SourceCodeSvg from './components/SourceCodeSvg';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Details from './components/Details';
import Author from './components/Author';
import data from './constants/blobPoints';
import random from './utils/random'
import ContactForm from './components/ContactForm';

function App() {
  const [rotation, setRotation] = useState(0)
  const [blobPoints, setPoints] = useState<number[][]>(data)
  const [d, setD] = useState<string>('')
  const [ModalOpen, setModalOpen] = useState(false)
  const [color, setColor] = useState('#6c63ff')
  // const [Picker, showPicker] = useState(false)
  const containerRef = useRef<HTMLElement>(null)
  
  let btnRef = useRef<HTMLButtonElement>(null)
  let blobRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    create()
  }, [])

  // common functions

  
  const create = () => {
    
    // making it morphing
    setRotation(rotation+40);
    // 25-75 is a sweet spot! :D
    let r1 = random(21, 25);
    let r2 = random(46,53);
    let r3 = random(79, 80);
    let r4 = random(18, 20);
    let r5 = random(7, 13);
    let r6 = random(-22, -19);
    let r7 = random(10, 14);
    
    // calculate coordinates against the four sides
    let r11 = random(75,85);
    let r22 = random(75,85);
    let r33 = random(35,45);
    let r44 = random(5,15);
    let r55 = random(10,15);
    let r66 = random(20,25);
    let r77 = random(70,75);
    
    let points = [[r1,r11], [r2,r22], [r3,r33], [r4,r44], [r5,r55], [r6,r66], [r7,r77]] 
    
    setPoints(points)
    // @ts-ignore
    setD(line().curve(curveCatmullRomClosed.alpha(1))(blobPoints))
  }

  const download = async () => {
    if (blobRef.current) {
      const svg = blobRef.current.innerHTML
      const blob = new Blob([svg.toString()])
      const link = document.createElement('a')
      link.href = (window.URL || window.webkitURL).createObjectURL(blob);
      link.download =  'blob.svg'
      link.click()
      link.remove()
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <Navigation />
      </header>
      <section ref={containerRef}>
        <div className="actions">
          <button onClick={download} className="btn secondayBtn">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><line x1="6" x2="24" y1="24" y2="24" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" points="11 15 15 19 19 15"/><line x1="15" x2="15" y1="19" y2="7" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/></svg>
          </button>
          <button ref={btnRef} onClick={create} className="btn">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentcolor" d="M16,12a1,1,0,0,1-.49.86l-5,3A1,1,0,0,1,10,16a1,1,0,0,1-.49-.13A1,1,0,0,1,9,15V9a1,1,0,0,1,1.51-.86l5,3A1,1,0,0,1,16,12Z"/><path fill="currentcolor" d="M21.92,5.09a1,1,0,0,0-1.07.15L19.94,6A9.84,9.84,0,0,0,12,2a10,10,0,1,0,9.42,13.33,1,1,0,0,0-1.89-.66A8,8,0,1,1,12,4a7.87,7.87,0,0,1,6.42,3.32l-1.07.92A1,1,0,0,0,18,10h3.5a1,1,0,0,0,1-1V6A1,1,0,0,0,21.92,5.09Z"/></svg>
          </button>
          <button onClick={() => setModalOpen(!ModalOpen)} className="btn secondayBtn">
            <SourceCodeSvg />
          </button>
        </div>

        <CustomModal myRef={containerRef} show={ModalOpen} setShow={setModalOpen} svgCode={blobRef.current?.innerHTML as string}/>

        <div id="blob" ref={blobRef}>
          <BlobMaker path={d} data={blobPoints} bounds={{ width: 200, height: 200 }} color={color}/>
        </div>

        <TwitterPicker
            className="twitterPicker"
            color={color}
            onChangeComplete={ (color) => setColor(color.hex) }
        />
      </section>

      <Details />

      {/* <!-- Eureka reiviews plugin --> */}
      {/* <!-- Paste this code at the place you want the button to appear --> */}
        <div className="ureka-btn-wrapper">
            <button id="reviews-tigger-btn" type="button" className="btn btn-primary ureka-btn" data-toggle="modal" data-target="#ureka-modal" data-site-id="bloby">
                Read Reviews from people near you
            </button>
        </div>

        {/* <!-- Eureka reiviews plugin --> */}
        {/* <!-- --------- END --------- --> */}

      <Author />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
