import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import {CopyToClipboard} from 'react-copy-to-clipboard';

interface CustomMadalProps {
    show: boolean,
    setShow: (value: boolean) => void,
    svgCode: string,
    myRef: any
}

const CustomModal: React.FC<CustomMadalProps> = ({show, setShow, svgCode, myRef, ...props}: CustomMadalProps) => {

    const [copied, setCopied] = useState(false)

    const onCloseModal = () => {
        setCopied(false)
        setShow(false)
    };

    useEffect(() => {
    }, [])
    
    
    return (
        <Modal classNames={{modal: "modal"}} open={show} onClose={onCloseModal} center container={myRef.current}>
            <h2>Copy SVG code</h2>
        <CopyToClipboard text={svgCode as string}
          onCopy={() => setCopied(true)}>
          <button className="copyBtn">{copied ? <span>Copied!</span> : 'Copy to clipboard'}</button> 
        </CopyToClipboard>
            
            <div className="codeBlock">
                {svgCode}
            </div>

        </Modal>
    )
}

export default CustomModal