import * as React from "react"

const SourceCodeSvg: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__svg6485"
      viewBox="0 0 16 16"
      height={28}
      width={24}
      {...props}
    >
      <g transform="translate(0 -1036.362)" id="prefix__layer1">
        <path
          style={{
            lineHeight: "normal",
            textIndent: 0,
            textAlign: "start",
            textDecorationLine: "none",
            textDecorationStyle: "solid",
            textDecorationColor: "#000",
            textTransform: "none",
            whiteSpace: "normal",
            isolation: "auto",
            mixBlendMode: "normal",
          }}
          d="M8.766 1038.475l-3.048 11.369 1.518.406 3.046-11.369zm3.158 1.964l-1.121 1.122 2.8 2.801-2.8 2.801 1.123 1.123 2.799-2.8 1.122-1.124-1.122-1.12zm-7.85 0l-2.799 2.799-1.122 1.122 1.122 1.123 2.801 2.8 1.121-1.122-2.8-2.8 2.8-2.802z"
          id="prefix__path5088-3"
          color="#000"
          fontStyle="normal"
          fontVariant="normal"
          fontWeight={400}
          fontStretch="normal"
          fontSize="medium"
          fontFamily="sans-serif"
          textDecoration="none"
          letterSpacing="normal"
          wordSpacing="normal"
          direction="ltr"
          writingMode="lr-tb"
          baselineShift="baseline"
          textAnchor="start"
          clipRule="nonzero"
          display="inline"
          overflow="visible"
          visibility="visible"
          opacity={1}
          colorInterpolation="sRGB"
          colorInterpolationFilters="linearRGB"
          fill="#000"
          fillOpacity={1}
          fillRule="evenodd"
          stroke="none"
          strokeWidth={1}
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={4}
          strokeDasharray="none"
          strokeDashoffset={0}
          strokeOpacity={1}
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          textRendering="auto"
        />
      </g>
      <style type="text/css" id="style5178" />
    </svg>
  )
}

export default SourceCodeSvg
