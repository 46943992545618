import React from 'react'

const Footer: React.FC = (props) => {
    return (
        <footer className="footer">
            <p>&copy; {new Date().getFullYear()} Bloby. Say Hi 👋 on Twitter <a href="https://twitter.com/EhThing">@EhThing</a></p>
        </footer>
    )
}

export default Footer