import React from 'react'

const Navigation: React.FC = (props) => {
    return (
        <div className="navbar">
            <p className="brand">Bloby</p>
            <p>
                <a className="twitter-share-button"
                    href="https://twitter.com/intent/tweet?text=Generate SVG blobs with bloby&url=https://bloby.co/" target="_blank" rel="noreferrer">
                    Share
                </a>
            </p>
        </div>
    )
}

export default Navigation