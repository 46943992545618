// For more help visit https://formspr.ee/react-help

import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
  const [state, handleSubmit] = useForm("mgeragnb");
  if (state.succeeded) {
      return <p className="successMsg">Thanks for joining! 😎</p>;
  }
  return (
      <form onSubmit={handleSubmit}>
      <input
        id="email"
        type="email" 
              name="email"
              placeholder="Enter Email"
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      <button type="submit" disabled={state.submitting}>
        Submit
      </button>
    </form>
  );
}

export default ContactForm