import React from 'react'

const Author: React.FC = () => {
    return (
        <section className="author">
            <h1>Author</h1>
            <p>Hello, I am Imran Khan. I am building some cool stuff 😎. Join the waitlist to get early access.</p>
        </section>
    )
}

export default Author
